<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style="height: calc(100vh - 83px);"
  >
    <q-card flat bordered style="width:1024px; " class="q-my-xl">
      <q-card-section class="row justify-end">
        <q-input
          outlined
          label="Cari Siswa"
          v-model="searchTerm"
          style="max-width:300px"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </q-card-section>
      <q-card-section horizontal>
        <q-card-section
          class="column q-pt-none justify-between"
          style="min-width:300px"
        >
          <div class="q-gutter-md">
            <q-select
              outlined
              v-model="selKelas"
              label="Kelas"
              :options="kelas"
            />
            <q-select
              outlined
              v-model="selKategori"
              label="Kategori"
              :options="kategori"
            />
          </div>
          <q-btn
            color="primary"
            label="TAMPILKAN"
            @click="getRanking"
            :disable="selKelas == null || selKategori == null"
          ></q-btn>
        </q-card-section>
        <q-markup-table
          flat
          bordered
          dense
          separator="horizontal"
          class="stickyHeaderColumn bg-indigo-1 full-width bg-grey-4"
          style="height: calc(100vh - 267px);"
        >
          <thead class="bg-indigo-5 text-white text-left">
            <tr>
              <th>nama</th>
              <th>nisn</th>
              <th v-for="(val, i) in mapel" :key="i">
                {{ val.nama }}
              </th>
              <th>total nilai</th>
              <th>rerata</th>
              <th>ranking</th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr v-for="(val, i) in filSiswa" :key="i">
              <td>{{ val.nama }}</td>
              <td>{{ val.nisn }}</td>
              <td v-for="(el, j) in val.listmapel" :key="j">
                {{ el.nilai }}
              </td>
              <td>{{ parseFloat(val.nilai_total).toFixed(2) }}</td>
              <td>{{ parseFloat(val.rerata).toFixed(2) }}</td>
              <td>{{ val.rank }}</td>
            </tr>
          </tbody>
        </q-markup-table></q-card-section
      >
    </q-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      mapel: [],

      kategori: ["Pengetahuan", "Keterampilan"],
      selKategori: null,

      kelas: [],
      selKelas: null,

      siswa: [],
    };
  },
  computed: {
    filSiswa() {
      if (this.searchTerm == "") return this.siswa;
      else {
        return this.siswa.filter((val) => {
          let cNama =
            val.nama.toLowerCase().indexOf(this.searchTerm.toLowerCase()) != -1;
          let cNISN = val.nisn.indexOf(this.searchTerm) != -1;
          return cNama || cNISN;
        });
      }
    },
  },
  async mounted() {
    await this.getListKelas();
  },
  methods: {
    async getmapel() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let resp = await this.$http.get(
        `/hasil/ranking/getmapelinkelas/${this.selKelas.value}`
      );
      this.mapel = resp.data;
      this.$q.loading.hide();
    },
    async getRanking() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      // await this.getmapel();
      let resp = null;
      if (this.selKategori == "Pengetahuan") {
        resp = await this.$http.get(
          `/hasil/ranking/getrankingpengetahuan/${
            this.selKelas.value
          }/${localStorage.getItem("id_tahun_ajaran")}`
        );
      } else if (this.selKategori == "Keterampilan") {
        resp = await this.$http.get(
          `/hasil/ranking/getrankingketerampilan/${
            this.selKelas.value
          }/${localStorage.getItem("id_tahun_ajaran")}`
        );
      }
      this.siswa = resp.data;
      this.mapel = [];
      for (let item of this.siswa[0].listmapel) {
        this.mapel.push({
          id: item.id_mapel,
          nama: item.nama_mapel,
        });
      }
      this.$q.loading.hide();
      this.sort();
    },
    sort() {
      let temp = JSON.parse(JSON.stringify(this.siswa));
      temp.sort(function(a, b) {
        return a.rerata + b.rerata;
      });
      let i = 1;
      for (let item of temp) {
        item.rank = i;
        i++;
      }
      this.siswa = temp;
    },
    async getListKelas() {
      this.$q.loading.show({ message: "Mohon Tunggu..." });
      let url = `/hasil/ranking/getkelas/${localStorage.getItem("jenjang")}`;
      let resp = await this.$http.get(url);
      this.kelas = resp.data;
      this.$q.loading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
